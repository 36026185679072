<template>
  <div v-if="canAccess('verifica_pagos_read')||canAccess('ingresa_pagos')">
    <BlockUI :blocked="loading" v-if="loading" :fullScreen="true" class="block-ui">
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12 p-md-2 p-mt-2">
          <span class="p-float-label">
              <Calendar id="start_date" :showIcon="true" v-model="filters.from"
                        dateFormat="yy-mm-dd"
                        :yearNavigator="true" yearRange="2000:2050" class="p-inputtext-sm"/>
              <label for="start_date">Desde</label>
          </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
         <span class="p-float-label">
            <Calendar id="end_date" :showIcon="true" v-model="filters.to"
                      dateFormat="yy-mm-dd"
                      :yearNavigator="true" yearRange="2000:2050" class="p-inputtext-sm"/>
            <label for="end_date">Hasta</label>
        </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
             <span class="p-float-label">
                <InputText id="enroll" v-model="filters.enrollment" @keyup.enter="filter"/>
                <label for="enroll">Matrícula/Nro. Recibo</label>
            </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
         <span class="p-float-label">
           <Dropdown id="status" v-model="filters.status" :options="status"
                     @change="filter"/>
            <label for="status">Estado</label>
        </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
           <span class="p-float-label">
             <Dropdown id="type_payment" v-model="filters.type_payment"
                       :options="typesPayment" @change="filter"
                       optionLabel="name"/>
              <label for="type_payment">Tipo de pago</label>
          </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2"  v-if="isAdmin">
        <span class="p-float-label">
           <Dropdown id="country" v-model="filters.country" :options="countries"
                     @change="filter"
                     optionLabel="country"/>
            <label for="country">País</label>
        </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2"  v-if="isAdmin">
         <span class="p-float-label">
           <Dropdown id="subsidiary" v-model="filters.subsidiary" :options="subsidiaries"
                     @change="filter"
                     optionLabel="name"/>
            <label for="subsidiary">Filial</label>
        </span>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
        <Button label="Clear" icon="pi pi-undo" class="p-button-secondary p-mr-2"
                @click="reload"/>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2">
        <Button label="Filtrar" icon="pi pi-search" class="p-button-info p-mr-2"
                @click="filter"/>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2"  v-if="isAdmin"   v-tooltip.bottom="'Debes seleccionar el país'">
        <Button label="Exportar" icon="pi pi-file-excel" class="p-button-success p-mr-2" :disabled="filters.country==null"
                @click="exportReport('XLS')"/>
      </div>
      <div class="p-col-12 p-md-2 p-mt-2"  v-else  v-tooltip.bottom="'Debes seleccionar un rango de fechas'">
        <Button label="Exportar" icon="pi pi-file-excel" class="p-button-success p-mr-2" :disabled="filters.from==null || filters.to==null"
                @click="exportReport('XLS')"  />
      </div>
      <div class="p-col-12 p-md-2 p-mt-2"  v-if="isAdmin"  v-tooltip.bottom="'Debes seleccionar el país'">
        <Button label="Imprimir" icon="pi pi-file-pdf" class="p-button-info p-mr-2" :disabled="filters.country==null"
                @click="exportReport('PDF')"  />
      </div>
      <div class="p-col-12 p-md-2 p-mt-2"  v-else  v-tooltip.bottom="'Debes seleccionar un rango de fechas'">
        <Button label="Imprimir" icon="pi pi-file-pdf" class="p-button-info p-mr-2" :disabled="filters.from==null || filters.to==null"
                @click="exportReport('PDF')"  />
      </div>


    </div>
    <div class="p-grid p-fluid dashboard" style="padding: 10px;background: white">
      <div class="card card-w-title" id="divCard" style="padding:0px;min-width: 350px;width: 100%; overflow-x: auto;">
        <DataTable :value="payments" :paginator="true" dataKey="id"
                   paginatorPosition="bottom"
                   :lazy="true"
                   :totalRecords="page.total"
                   :rows="page.per_page"
                   :loading="loading"
                   @page="onPage($event)"
                   class="p-datatable-sm p-datatable-striped"
                   style=""
        >
          <template #header v-if="enroll">
            <Toolbar class="p-col-12 p-mb-1">
              <template #left style="width: 500px">
                <div class="p-col-12">
                  <span>Valor inicial: <strong>{{ $filters.moneyFormat(enroll.cost) }}</strong></span>
                  <span> | </span>
                  <span>Valor pendiente: <strong>{{ $filters.moneyFormat(balance) }}</strong></span>
                </div>

              </template>
              <template #right>
                <Button label="Nuevo pago" icon="pi pi-plus" class="p-button-success p-mr-2"
                        @click="newPayment()"/>
              </template>
            </Toolbar>
          </template>
          <Column header="Matrícula" headerStyle="width:90px;text-align:center">
            <template #body="slotProps">
              {{ slotProps.data.enrollment ? slotProps.data.enrollment.sequential : '' }}
            </template>
          </Column>
          <Column header="Titular" headerStyle="width: 150px;text-align:center">
            <template #body="slotProps">
              {{ slotProps.data.enrollment?.contract?.holder?.name }}
              {{ slotProps.data.enrollment?.contract?.holder?.last_name }}
            </template>
          </Column>
          <Column header="País" :headerStyle="!enroll ? 'width: 90px;text-align:center': 'width: 0px; visibility: hidden;text-align:center'">
            <template #body="slotProps" v-if="!enroll">
              {{ slotProps.data.enrollment?.contract?.country?.name }}
            </template>
          </Column>
          <Column header="Filial" :headerStyle="!enroll ? 'width: 90px;text-align:center': 'width: 0px; visibility: hidden;text-align:center'">
            <template #body="slotProps" v-if="!enroll">
              {{ slotProps.data.enrollment?.subsidiary?.name }}
            </template>
          </Column>
          <Column header="Fecha" field="date_payment" headerStyle="width: 100px;text-align:center">
            <template #body="slotProps">
              {{ slotProps.data?.date_payment?$filters.dateFormat(slotProps.data?.date_payment):'' }}
            </template>
          </Column>
          <Column header="Tipo de pago" headerStyle="width: 100px;text-align:center">
            <template #body="slotProps">
              {{ $filters.paymentType(slotProps.data.type_payment) }}
            </template>
          </Column>
          <Column header="Estado" headerStyle="width: 100px;text-align:center">
            <template #body="slotProps">
              <Tag class="p-mr-2" rounded icon="pi pi-check" severity="success" :value="slotProps.data.status"
                   v-if="slotProps.data.status === approvedKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-times" severity="danger" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === denyKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-times" severity="warning" :value="slotProps.data.status"
                   v-else-if="slotProps.data.status === transitionKey"></Tag>
              <Tag class="p-mr-2" rounded icon="pi pi-info-circle" v-else :value="slotProps.data.status"></Tag>
            </template>
          </Column>
          <Column header="Monto" headerStyle="width: 100px;text-align:center">
            <template #body="slotProps">
              {{ $filters.moneyFormat(slotProps.data.value_paid) }}
            </template>
          </Column>
          <Column header="Saldo" headerStyle="width: 100px;text-align:center">
            <template #body="slotProps">
              {{ $filters.moneyFormat(slotProps.data.balance) }}
            </template>
          </Column>
          <Column header="Nro. Recibo" headerStyle="width: 100px;text-align:center">
            <template #body="slotProps">
              {{ slotProps.data.nro_doc??'N/A' }}
            </template>
          </Column>
          <Column header="Opciones" :headerStyle="!enroll ? 'width: 70px': 'width: 0px; visibility: hidden'">
            <template #body="slotProps" v-if="!enroll">
              <Button v-tooltip="'Verificar pago'" icon="pi pi-check" class="p-button-rounded p-button-info  p-mr-2"
                      @click="modalView(slotProps.data, false)"
                      :disabled="(slotProps.data.status !== pendingKey && slotProps.data.status !== transitionKey) ? 'disabled': undefined"/>
              <Button v-tooltip="'Ver Registro'" icon="pi pi-eye" class="p-button-rounded p-button-warning  p-mr-2"
                      @click="modalView(slotProps.data, true)"
                      v-if="(slotProps.data.status !== pendingKey && slotProps.data.status !== transitionKey)"/>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <Dialog position="top"  v-if="canAccess('ingresa_pagos')"
             v-model:visible="editDialog"
            :style="{width: '1000px'}"
            header="Datos de pago"
            :modal="true">

        <ScrollPanel style="width: 100%; height: 550px" class="custombar1">
          <h5>Titular: {{ enroll?.contract?.holder?.name }} {{ enroll?.contract?.holder?.last_name }}</h5>
          <payment :show-upload-documents="true" ref="paymentForm" :enroll="enroll" v-if="editDialog" :block="false"></payment>
        </ScrollPanel>
      <template #footer>
        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog = false"/>
        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="savePayment"/>
      </template>
    </Dialog>
    <Dialog v-if="canAccess('verifica_pagos_read')" v-model:visible="verifyDataDialog" :style="{width: '900px'}"
            header="Datos de pago" :modal="true"
            class="p-fluid">
      <ScrollPanel style="width: 100%; height: 550px" class="custombar1">
        <div class="p-grid">
          <div class="p-col-12 p-md-3 p-text-center">
            <b>SALDO</b><br/>{{
              formatMoney((currentItem.enrollment.plan?.total_value ?? 0) - currentItem.enrollment.amount_paid)
            }}
          </div>
          <div class="p-col-12 p-md-3 p-text-center">
            <b>VALOR TOTAL</b><br/>{{ formatMoney(currentItem.enrollment.plan?.total_value) }}
          </div>
          <div class="p-col-12 p-md-3 p-text-center">
            <b>MONTO COBRADO</b><br/>{{ formatMoney(currentItem.enrollment.amount_paid) }}
          </div>
          <div class="p-col-12 p-md-3 p-text-center">
            <b>PORCENTAJE RECAUDADO</b><br/>{{
              parseFloat((currentItem.enrollment.amount_paid * 100) / currentItem.enrollment.plan?.total_value ?? 1).toFixed(2)
            }}%
          </div>
        </div>

        <label><strong>Titular:</strong>
          {{ currentItem.enrollment?.contract?.holder?.name }} {{ currentItem.enrollment?.contract?.holder?.last_name }} => {{ currentItem.enrollment?.contract?.holder?.email }}</label>
        <br/>
        <label><strong>Reporta Pago:</strong> {{ currentItem.register_user?.name }} => {{ currentItem.register_user?.email }}</label>
        <payment ref="paymentForm" :show-upload-documents="false" :enroll="enroll" :payment="currentItem" :block="true"
                 v-if="verifyDataDialog"></payment>
      </ScrollPanel>

      <template #footer v-if="!onlyView">
        <Button label="Denegar" icon="pi pi-times" class="p-button-danger" @click="verify('deny')"/>
        <Button v-if="isTransition()" label="En Transición" icon="pi pi-clock" class="p-button-warning" @click="verify('transition')"/>
        <Button label="Aprobar" icon="pi pi-check" class="p-button-success" @click="verify('approve')"/>
      </template>
    </Dialog>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import bouncer from "../../../../helpers/bouncer";
import service from '../../service/payment.service'
import catalogService from "../../../core/service/catalog.service";
import {defineAsyncComponent} from "vue";
import {mapGetters, mapState} from "vuex";
import moment from "moment";
import FileSaver from 'file-saver';
import report from '../../../reports/service/report.service'

export default {
  mixins: [bouncer],
  name: "PaymentList",
  components: {
    payment: defineAsyncComponent(() => import('./Payment')),
  },
  props: {
    enroll: Object,
    edit: Boolean,
  },
  data() {
    return {
      onlyView: false,
      haveSearch:false,
      columns: [
        {field: 'code', header: 'Codigo'},
        {field: 'date_payment', header: 'Fecha'},
        {field: 'type.description', header: 'Forma de pago'}
      ],
      page: {
        page: 1,
        per_page: 8,
        total: 0,
        order: "desc"
      },
      allKey: 'Todos',
      pendingKey: 'Pendiente',
      approvedKey: 'Aprobado',
      denyKey: 'Denegado',
      transitionKey: 'En Transición',
      payments: [],
      data: {},
      filters: {status: 'Todos'},
      submitted: false,
      loading: false,
      editDialog: false,
      verifyDataDialog: false,
      search: '',
      all: {id: 'ALL', name: 'Todos'},
      inscriptionKey: {id: 'INSCRIPTION', name: 'Inscripción'},
      quotaKey: {id: 'FEE', name: 'Cuotas'},
      countries: [],
      country: {},
      report: report('payments'),
    }
  },
  methods: {
    isTransition(){
      return this.currentItem.type_payment ===  this.inscriptionKey.id
          &&  this.currentItem.status!== this.transitionKey;
    },
    modalView(itemToView, isRead){
      this.currentItem = itemToView;
      this.verifyDataDialog = true;
      this.onlyView = isRead;
    },
    exportReport(command) {
      this.loading = true;
      this.report.getPayments({
        from : this.filters.from ? moment(this.filters.from).format("YYYY-MM-DD") : undefined,
        to : this.filters.from ? moment(this.filters.to).format("YYYY-MM-DD") : undefined,
        status : this.filters.status,
        sequential : this.filters.enrollment,
        type_payment : this.filters.type_payment ? this.filters.type_payment.id : undefined,
        country_id : this.filters.country ? this.filters.country.id : undefined,
       subsidiary: this.filters.subsidiary ? this.filters.subsidiary.id : undefined,
        export: command
      })
          .then(x => {
            if(command=='PDF'){
              this.info = x.data;
              var byteCharacters = atob(x.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {type: "application/pdf"});

              FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
            }else{
              window.open(x.data.url_file)
            }

          }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => this.loading = false)

    },
    formatMoney(val) {
      return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })).format(val);
    },
    verify(verifyKey) {
      this.loading = true;
      service.verify(this.currentItem.id, verifyKey).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Acción realizada con éxito',
          detail: 'Se guardó correctamente',
          life: this.$utils.toastLifeTime()
        });
        this.getData();
        this.verifyDataDialog = false;
      }).catch((err) => {
        this.loading = false;
        this.verifyDataDialog = false;
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })
    },
    onPage(event) {
      this.page.page = event.page + 1;
      this.filter()
    },
    newPayment() {
      this.editDialog = true;
      this.$store.dispatch('enroll/setBalance', this.enroll.balance);
    },
    getData(params = {}) {
      this.loading = true;
      if (this.enroll) {
        service.getByEnrollment(this.enroll.id, this.page).then(x => {
          this.payments = x.data.data;
          this.page.total = x.data.total;
        }).finally(() => this.loading = false)
      } else {
        const config = {
          ...this.page,
          ...params,
          country_id: this.isAdmin ? params.country_id : this.user?.employee?.country_id
        };
        service.get(null, config).then(x => {
          this.payments = x.data.data;
          this.page.total = x.data.total;
        }).finally(() => this.loading = false)
      }

    },
    reload() {
      this.filters = {status: this.allKey};
      this.country = {};
      this.getData();
    },
    filter() {
      const from = this.filters.from ? moment(this.filters.from).format("YYYY-MM-DD") : undefined;
      const to = this.filters.from ? moment(this.filters.to).format("YYYY-MM-DD") : undefined;
      const status = this.filters.status;
      const sequential = this.filters.enrollment;
      const type_payment = this.filters.type_payment ? this.filters.type_payment.id : undefined;
      const country_id = this.filters.country ? this.filters.country.id : undefined;
      const subsidiary = this.filters.subsidiary ? this.filters.subsidiary.id : undefined;
      const params = {
        from, to, status, sequential, type_payment, country_id, subsidiary
      };
      this.getData(params)
    },
    savePayment() {
      const ev = this.$refs.paymentForm.$refs.paymentForm.save();
      if (ev) {
        ev.then(() => {
          this.getData();
          this.editDialog = false;
        }).catch(err => {
          const message = err.response.data;
          let error = this.$utils.formatError(message);
          this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
        })
      }
    },
    getCounties() {
      catalogService.get(`select/countries/subsidiaries`).then(response => {
        this.countries = response.data;
      });
    },
  },
  computed: {
    initialValue() {
      return this.payments.find(x => x.value_paid === 0)
    },
    ...mapState('enroll', ['balance']),
    ...mapState('auth', ['user']),
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),

    status() {
      return [this.allKey, this.pendingKey, this.approvedKey, this.denyKey,this.transitionKey]
    },
    typesPayment() {
      return [this.all, this.inscriptionKey, this.quotaKey]
    },
    subsidiaries() {
      return this.filters.country ? this.filters.country.subsidiaries : []
    }
  },
  mounted() {
    if (this.canAccess('verifica_pagos_read') || this.canAccess('ingresa_pagos')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Gestión operativa', url: "javascript:void(0);"},
        {label: 'Verificar pagos', url: "javascript:void(0);"},
      ]);
      this.getCounties();
      this.getData();
    }
    document.getElementById('divCard').addEventListener(
        'scroll',
        function () {
          var scrollTop = document.getElementById("divCard").scrollLeft;
          if (scrollTop == 0) {
            document.getElementsByClassName("p-paginator")[0].style.cssText = "margin-left:0px;width:100%";
          } else {
            document.getElementsByClassName("p-paginator")[0].style.cssText = "margin-left:" + (document.getElementById("divCard").scrollLeft) + "px;width:100%";
          }
        },
        false
    );

  }
}
</script>

<style>


.p-fieldset {
  margin-top: 15px;
}

.p-field {
  padding-top: 15px;
}

.p-dialog-footer {
  padding: 0;
  margin-bottom: -15px;
}

.p-grid {
  margin-top: 15px;
}

.p-toolbar {
  background: none;
  border: none;
}

.p-inputtext {
  width: 100%;
}

.p-autocomplete {
  height: 30px !important;
}

.df, .df #status, .p-fluid .p-dropdown {
  height: 100%;
}

.center, .p-dialog .p-dialog-footer {
  text-align: center;
}

.p-message-wrapper > span {
  display: none !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  text-align: center !important;
}

.p-datatable table {
  table-layout: auto;
}

.table-responsive {
  overflow-x: auto;
}

thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.thC {
  background: #f8f9fa !important;
}

.tdC {
  padding: 4px !important;
  text-align: center;
}

.p-dropdown-panel{
  left: 0!important;
}
.p-scrollpanel p {
  padding: .5rem;
  line-height: 1.5;
  margin: 0;
}

.p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
  border-right: 9px solid var(--surface-border);
}

.p-scrollpanel.custombar1 .p-scrollpanel-bar {
  background-color: var(--primary-color);
  opacity: 1;
  transition: background-color .2s;
}

.p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
  background-color: #007ad9;
}

.p-scrollpanel.custombar2 .p-scrollpanel-wrapper {
  border-right: 9px solid var(--surface-border);
  border-bottom: 9px solid var(--surface-border);
}

.p-scrollpanel.custombar2 .p-scrollpanel-bar {
  background-color: var(--surface-ground);
  border-radius: 0;
  opacity: 1;
  transition: background-color .2s;
}

.block-ui {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000054;
  z-index: 99999;
}

</style>
